import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_TW";
import { useDispatch, useSelector } from "react-redux";
import { fetchRetailList, financeOrderState, setOrderListFilter } from "@redux/financeOrderSlice";
import { ContractType } from "@api/financeOrderApi";

const Wrapper = styled(Form)`
  padding: 20px;
`;
const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const { Option } = Select;

export default function Filter() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { orderListFilter, retailList } = useSelector(financeOrderState);
  const retailListResult = retailList.results;

  const clearFilter = () => {
    form.resetFields();
    dispatch(
      setOrderListFilter({
        sku: undefined,
        contractType: undefined,
        retailerId: undefined,
        accountDateAfter: undefined,
        accountDateBefore: undefined,
      }),
    );
  };

  const onSubmit = (values: any) => {
    dispatch(
      setOrderListFilter({
        ...orderListFilter,
        sku: values.sku,
        contractType: values.contractType,
        retailerId: values.retailerId,
        accountDateAfter: values.accountDate ? moment(values.accountDate[0]).format("YYYY-MM-DD") : undefined,
        accountDateBefore: values.accountDate ? moment(values.accountDate[1]).format("YYYY-MM-DD") : undefined,
        invoiceNumber: values.invoiceNumber,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchRetailList({}));
  }, [dispatch]);

  return (
    <Wrapper colon={false} labelAlign="left" form={form} initialValues={{}} onFinish={onSubmit}>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="Sku(品號)" name="sku">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="發票日期" name="accountDate">
            <CustomRangePicker locale={locale} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="供應/經銷商" name="retailerId">
            <Select placeholder="請選擇">
              {retailListResult.map((retailer) => {
                return (
                  <Option key={retailer.id} value={retailer.id}>
                    {retailer.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="類型" name="contractType">
            <Select placeholder="請選擇">
              <Option value={ContractType.CONSIGNMENT}>寄倉</Option>
              <Option value={ContractType.TRANSFER}>轉單</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="發票號碼" name="invoiceNumber">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30} justify="end">
        <Col>
          <Row>
            <CustomButton onClick={clearFilter}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
