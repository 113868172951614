import { warehouseCodeOptions } from "@constant/WarehouseCodeValue";
import {
  fetchReturnPlatformList,
  fetchReturnProductAppList,
  resetAppListParams,
  returnProductState,
  updateAppListParams,
} from "@redux/sharing/ReturnProductManagementSlice";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";

import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const FilterWrapper = styled.div<{ display: boolean }>`
  padding: "43px 13px";
  display: ${({ display }) => (display ? "block" : "none")};
`;

const { RangePicker } = DatePicker;

type Props = {
  open: boolean;
};

export default function FilterForm(props: Props) {
  const { open } = props;
  const dispatch = useDispatch();
  const {
    returnProductAppListParams,
    retailList,
    returnProductAppList: { staffs },
  } = useSelector(returnProductState);

  const [form] = Form.useForm();
  const dateFormatter = (date: moment.Moment | null) => date?.format("YYYY-MM-DD");
  const clearFilter = () => {
    dispatch(resetAppListParams());
    form.resetFields();
    dispatch(fetchReturnProductAppList({ limit: returnProductAppListParams.limit, offset: 0 }));
  };

  const platformOptions = retailList?.map((item) => ({ label: item.name, value: item.name }));

  const confirmFilter = (e: React.FormEvent<HTMLFormElement>) => {
    const { createAt, stockinDate, ...formDate } = form.getFieldsValue();
    const params = { ...returnProductAppListParams, ...formDate };
    dispatch(updateAppListParams(params));
  };

  useEffect(() => {
    dispatch(fetchReturnPlatformList({ contractTypes: [10, 30] }));
  }, [dispatch]);

  return (
    <FilterWrapper display={open}>
      <Form onFinish={confirmFilter} form={form} labelCol={{ span: 6 }} labelAlign="left">
        <Row gutter={[12, 4]}>
          <Col span={8}>
            <Form.Item label="SKU(品號)" name="sku">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="入庫倉庫" name="warehouseCode">
              <Select
                options={warehouseCodeOptions.filter((option) => option.value !== "STORE")}
                placeholder="請選擇"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="退貨單號" name="returnNumber">
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 4]}>
          <Col span={8}>
            <Form.Item label="通路/平台" name="platform">
              <Select options={platformOptions} placeholder="請選擇" allowClear showSearch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="是否入庫" name="isReturnToWarehouse">
              <Select
                options={[
                  { label: "是", value: true },
                  { label: "否", value: false },
                ]}
                placeholder="請選擇"
                allowClear
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="建立時間" name="createAt">
              <Form.Item name="createdDtAfter" noStyle />
              <Form.Item name="createdDtBefore" noStyle />
              <RangePicker
                onChange={(date) => {
                  if (!date) return;
                  form.setFieldValue("createdDtAfter", dateFormatter(date[0]));
                  form.setFieldValue("createdDtBefore", dateFormatter(date[1]));
                }}
                placeholder={["開始日期", "結束日期"]}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 4]}>
          <Col span={8}>
            <Form.Item label="建立人員" name="staffId">
              <Select
                options={staffs
                  // 系統自動建立的退貨單staff會是null
                  ?.filter((item) => !!item.staffName)
                  .map((item) => ({ label: item.staffName, value: item.staffId }))}
                placeholder="請選擇"
                allowClear
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="實際入庫時間" name="stockinDate">
              <Form.Item name="stockinDateAfter" noStyle />
              <Form.Item name="stockinDateBefore" noStyle />
              <RangePicker
                onChange={(date) => {
                  if (!date) return;
                  form.setFieldValue("stockinDateAfter", dateFormatter(date[0]));
                  form.setFieldValue("stockinDateBefore", dateFormatter(date[1]));
                }}
                placeholder={["開始日期", "結束日期"]}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item name="noStockinDate" valuePropName="checked">
              <Checkbox>顯示未有實際入庫時間的進倉單</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 4]} justify="end">
          <Col>
            <Button onClick={clearFilter}>清除篩選條件</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Col>
        </Row>
      </Form>
    </FilterWrapper>
  );
}
