import React from "react";
import styled from "styled-components";
import { setReturnOrderListFilter, returnOrderState } from "@redux/returnOrderSlice";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_TW";
import { OrderStatusLabel } from "@constant/OrderStatus";
import { useDispatch, useSelector } from "react-redux";

const Wrapper = styled(Form)`
  padding: 20px;
`;
const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const { Option } = Select;

export default function Filter() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { returnOrderListFilter, externalWarehouseList } = useSelector(returnOrderState);

  const clearFilter = () => {
    form.resetFields();
    dispatch(
      setReturnOrderListFilter({
        orderNumber: undefined,
        sku: undefined,
        status: undefined,
        estDeployedDateAfter: undefined,
        estDeployedDateBefore: undefined,
        shippingDateAfter: undefined,
        shippingDateBefore: undefined,
        approvalStatus: undefined,
        sourceWarehouseCode: undefined,
        destWarehouseCode: undefined,
        limit: 20,
        offset: 0,
      }),
    );
  };

  const onSubmit = (values: any) => {
    dispatch(
      setReturnOrderListFilter({
        ...returnOrderListFilter,
        orderNumber: values.orderNumber,
        sku: values.sku,
        status: values.status,
        estDeployedDateAfter: values.estDeployedDate
          ? moment(values.estDeployedDate[0]).format("YYYY-MM-DD")
          : undefined,
        estDeployedDateBefore: values.estDeployedDate
          ? moment(values.estDeployedDate[1]).format("YYYY-MM-DD")
          : undefined,
        shippingDateAfter: values.shippingDate ? moment(values.shippingDate[0]).format("YYYY-MM-DD") : undefined,
        shippingDateBefore: values.shippingDate ? moment(values.shippingDate[1]).format("YYYY-MM-DD") : undefined,
        approvalStatus: values.approvalStatus,
        sourceWarehouseCode: values.sourceWarehouseCode,
        destWarehouseCode: values.destWarehouseCode,
      }),
    );
  };

  return (
    <Wrapper colon={false} labelAlign="left" form={form} initialValues={{}} onFinish={onSubmit}>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="訂單編號" name="orderNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Sku(品號)" name="sku">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="訂單狀態" name="status">
            <Select placeholder="請選擇">
              {Object.entries(OrderStatusLabel).map(([key, value]) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="預期拋單時間" name="estDeployedDate">
            <CustomRangePicker locale={locale} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="實際出貨時間" name="shippingDate">
            <CustomRangePicker locale={locale} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="審核狀態" name="approvalStatus">
            <Select placeholder="請選擇">
              <Option value={1}>待審核</Option>
              <Option value={2}>審核已通過</Option>
              <Option value={3}>審核不通過</Option>
              <Option value={4}>不需審核</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="出庫倉庫" name="sourceWarehouseCode">
            <Select placeholder="請選擇">
              {externalWarehouseList.map((item) => (
                <Select.Option key={item.code} value={item.code}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="入庫倉庫" name="destWarehouseCode">
            <Select placeholder="請選擇">
              <Option value="RENTRAP">藍田</Option>
              <Option value="TCAT">黑貓</Option>
              <Option value="STORE">門市倉庫</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30} justify="end">
        <Col>
          <Row>
            <CustomButton onClick={clearFilter}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
