import React, { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import PageTitle from "@component/PageTitle";
import {
  fetchReturnOrderList,
  returnOrderState,
  setReturnOrderListFilter,
  ReturnOrderListFilter,
  fetchDeleteReturnOrder,
  fetchExternalWarehouseList,
  fetchInternalWarehouseList,
} from "@redux/returnOrderSlice";
import { Button, Col, Modal, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { StyledSection } from "src/styles/common";
import IntegratedTable from "@component/Table/IntegratedTable";
import { Link } from "react-router-dom";
import { OrderStatus, OrderStatusLabel } from "@constant/OrderStatus";
import { ReviewStateByPositionLabel } from "@constant/ReviewState";
import EditOrderForm from "./EditOrderForm";
import Filter from "./Filter";
import { ReturnOrderDetail } from "./interfaces";

export default function ReturnOrderListPage() {
  const dispatch = useDispatch();
  const { returnOrderList, returnOrderListFilter, isFetching } = useSelector(returnOrderState);
  const [showPopup, setShowPopup] = useState(false);
  const [openFilterForm, setOpenFilterForm] = useState(false);

  const toggleFilterForm = () => {
    setOpenFilterForm(!openFilterForm);
  };

  const toggleNewOrderForm = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    dispatch(fetchReturnOrderList(returnOrderListFilter));
  }, [dispatch, returnOrderListFilter]);

  const checkDisabledOrder = (record: any) => ({ className: record.destroyedAt && "ur-disabled-text" });

  const deleteOrder = (orderId: number) => {
    Modal.warn({
      title: "你確定要作廢這筆資料？",
      okText: "是",
      onOk() {
        dispatch(fetchDeleteReturnOrder(orderId));
      },
      maskClosable: true,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "訂單編號",
      dataIndex: "orderNumber",
    },
    {
      title: "出庫倉庫",
      dataIndex: "sourceWarehouseName",
    },
    {
      title: "入庫倉庫",
      dataIndex: "destWarehouseName",
    },
    {
      title: "審核狀態",
      dataIndex: "approvalStatus",
      render: (data: number) => ReviewStateByPositionLabel[data],
    },
    {
      title: "訂單狀態",
      dataIndex: "status",
      render: (data: OrderStatus, values) => <div>{OrderStatusLabel[data]}</div>,
    },
    {
      title: "預期拋單日期",
      dataIndex: "estDeployedDate",
      sorter: true,
    },
    {
      title: "實際出貨時間",
      dataIndex: "shippingAt",
      sorter: true,
    },
    {
      title: "出貨商品總數",
      dataIndex: "totalQty",
      sorter: true,
    },
    {
      title: "總成本",
      dataIndex: "totalCost",
      sorter: true,
    },
    {
      title: "建立人員",
      dataIndex: "staffName",
    },
    {
      title: "建立時間",
      dataIndex: "createdAt",
    },
    {
      title: "實際入庫時間",
      dataIndex: "arrivedAt",
    },
    {
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (id: number, record: ReturnOrderDetail) => {
        return !record.canEdit ? (
          <Link to={`/return-inventory/edit/${id}`} target="_blank">
            檢視
          </Link>
        ) : (
          <Link to={`/return-inventory/edit/${id}`} target="_blank">
            編輯
          </Link>
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      fixed: "right",
      render: (_, record: ReturnOrderDetail) => (
        <Button
          type="link"
          onClick={() => {
            deleteOrder(record.id);
          }}
          disabled={!record.canCancel}
        >
          作廢
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchExternalWarehouseList());
    dispatch(fetchInternalWarehouseList());
  }, [dispatch]);

  return (
    <>
      <PageTitle title="調撥出貨" />
      <StyledSection>
        <Row justify="space-between" style={{ marginBottom: 21 }} align="middle">
          <Col span={12}>
            <Button type="primary" icon={<PlusCircleTwoTone twoToneColor="#1890FF" />} onClick={toggleNewOrderForm}>
              新增調撥單
            </Button>
            <Button style={{ marginLeft: 10 }} icon={<FilterTwoTone />} onClick={toggleFilterForm}>
              篩選
            </Button>
          </Col>
        </Row>
        {openFilterForm && <Filter />}
        {showPopup && <EditOrderForm onClose={() => setShowPopup(false)} />}
        <IntegratedTable
          isPagination
          paginationConfig={{
            totalCount: returnOrderList.count,
            updateFilterParams: (filter: ReturnOrderListFilter) => dispatch(setReturnOrderListFilter(filter)),
            filterParams: returnOrderListFilter,
          }}
          loading={isFetching}
          columns={columns}
          dataSource={returnOrderList.results}
          scroll={{ x: "max-content" }}
          rowKey={(record: any) => record.id}
          onRow={checkDisabledOrder}
        />
      </StyledSection>
    </>
  );
}
